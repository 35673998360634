<template>
  <div class="progress" :class="getProgressClass">
    <div class="dial-container">
      <Progress class="dial dial-a" 
        :strokeColor="`#${getProgressColor}`" 
        :transitionDuration="500" 
        :radius="9" 
        :strokeWidth="2" 
        :value="getDialValue"
        >
          <div class="content" :class="{'active' : isActive}"><span :class="getProgressClass">•</span></div>
      </Progress>
    </div>
    <span class="name">
      <!-- {{isActive ? 'x' : '•' }} -->
      {{ close ? `Close` : getName }}
    </span>
  </div>
</template>

<script>
  import Progress from "easy-circular-progress";
  // import API from 'app/axios';

  export default {
    components: {
      Progress,
    },
    props: {
      slug: {
        type: String,
        default: null,
      },
      active: {
        type: Boolean,
        default: false,
      },
      close: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        iInterval: 250, // tick every 100ms
        iSeconds: 0,
        iMax: 60,
        oInitial: {},
      }
    },
    mounted() {
      if (!this.slug) {
        // console.warn(`Progress: no slug (${this.slug})`);
        return;
      }

      this.oInitial = this.$store.getters.readState(this.slug) || {};
      if (this.oInitial.code) {
        this.iSeconds = this.oInitial.time;
      }
      // console.log(`${this.slug} -- Initial State: ${this.oInitial.code} (${this.iSeconds})`, this.oInitial);
      // API
      //   .get(`/account/read-status?slugs=${this.slug}`, {})
      //   .then(response => {
      //     // console.log(`Read Status ${this.slug}: `, response?.data);
      //   });

      window.setInterval(this.tick, this.iInterval);
    },
    computed: {
      isActive() {
        return this.active;
      },
      getName() {
        let i = this.getProgressValue;
        // $green-tone-3: #14B31F;
        // $orange-tone-3: #FF5717;
        // $red-tone-3: #EE002C;
        if (i < 20) {
          return 'New';
        } else if (i < 40) {
          return 'Glanced';
        } else if (i < 60) {
          return 'Skimmed';
        } else {
          return 'Read';
        }
      },
      getDialValue() {
        return Math.round(this.getProgressValue / 5) * 5;
      },
      getProgressValue() {
        return Math.min(100, Math.round((this.iSeconds / this.iMax) * 100));
      },
      getProgressClass() {
        let i = this.getProgressValue;
        if (i < 15) {
          return 'preview';
        } else if (i < 33) {
          return 'glanced';
        } else if (i < 66) {
          return 'skimmed';
        } else {
          return 'read';
        }
      },
      getProgressColor() {
        let i = this.getProgressValue;
        // $green-tone-3: #14B31F;
        // $orange-tone-3: #FF5717;
        // $red-tone-3: #EE002C;
        if (i < 15) {
          return '444444';
        } else if (i < 33) {
          return 'EE002C';
        } else if (i < 66) {
          return 'FF5717';
        } else {
          return '14B31F';
        }
      },
    },
    methods: {
      tick() {
        if (!this.isActive) {
          return;
        }
        this.iSeconds += (this.iInterval / 1000);
      },
    },
    watch: {
      getDialValue: function (iSeconds) {
        let state = this.getName.toLowerCase();
        
        // "new" is not really a state -- it should be "glanced"
        if (state == 'new') {
          state = 'glanced';
        }

        let oPayload = {
          time: iSeconds,
          story: this.slug,
          state: state,
          article: {
            slug: '',
          },
        }

        this.EventBus.$emit("trackArticleAction", oPayload);
        // console.info('[ReadState] - Not tracking locally: ', oPayload);
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/v5.scss';

  @keyframes heartbeat {
    // 0%   { color: $yellow-tone-7; }
    // 33%  { color: $yellow-tone-3; }
    // 66%  { color: $yellow-tone-3; }
    // 100% { color: $yellow-tone-7; }
    0%   { opacity: 0; }
    33%  { opacity: 1; }
    66%  { opacity: 1; }
    100% { opacity: 0; }
  }

  .progress {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 1rem 0 1rem;
    border-radius: 500px;
    background: $neutral-500;
    color: $neutral-400;
    margin-bottom: 0.5rem;
    @include transition;
    position: relative;

    .mq-sm & {
      // not on the modal, just within the homepage.. 
      .v5-section & {
        position: absolute;
        top: 1rem;
      }
    }

    .darkmode & {
      background: $neutral-300;
    }

    &.glanced {
      background: rgba($red-tone-3, 0.1);
      color: $red-tone-3;
    }

    &.skimmed {
      background: rgba($orange-tone-3, 0.1);
      color: $orange-tone-3;
    }

    &.read {
      background: rgba($green-tone-3, 0.1);
      color: $green-tone-3;
    }

    .content {
      position: relative; 
      top: -3px;
      opacity: 0;


      &.active {
        opacity: 1;
        animation: heartbeat 1s infinite;
      }

      .glanced {
        color: $red-tone-3;
      }

      .skimmed {
        color: $orange-tone-3;
      }

      .read {
        color: $green-tone-3;
      }
    }

    .dial-container {
      display: flex;
      justify-content: center;
      padding-top: 1px;
      padding-left: 1px;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.3rem;
      top: 0px;
      position: relative;
    }

    .name {
      display: flex;
      justify-content: center;
      font-weight: bold;
      font-size: 0.8rem;
      width: 60px; // Fixed width to stop width changing on label length
      padding-bottom: 2px;
    }
  }
</style>