import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export default {
  data: function() {
    return {
      raw: {},
    };
  },
  computed: {
    model() {
      return this.$dataEngine.storyGet();
    },
    clusters() {
      return this.$dataEngine.clustersGet();
    },
    articles() {
      return this.model?.articles;
    },
    /**
     *  Basic data..
     */
    image() {
      return this.model.image;
    },
    images() {
      return this.articles?.map(a => a.image);
    },
    photos() {
      return this.articles?.filter(a => a.photo)?.map(a => a.image);
    },
    headline() {
      return this.model.name;
    },
    synopsis() {
      return this.model.synopsis;
    },
    createdAt() {
      return this.model.created_at;
    },
    lastPublishedAt() {
      return this.model.last_published_at;
    },
    topics() {
      return this.model.topics?.filter(a => a.meta.image)?.slice(0,3);
    },
    


    /**
     *  DEFUNCT?? 
     */

    getCurrentStoryIndex() {
      return this.raw?.data?.sections?.findIndex(a => a.slug == this.slug);
    },
    getCurrentStory() {
      return this.raw?.data?.sections?.[this.getCurrentStoryIndex];
    },
    getNextStory() {
      return this.raw?.data?.sections?.[this.getCurrentStoryIndex + 1] || {};
    },
    getPrevStory() {
      return this.raw?.data?.sections?.[this.getCurrentStoryIndex - 1] || {};
    },









    /**
     *  Component display utilities\
     */
    getViewBindingClass() {
      return `view-bind-${this.getViewBinding}`;
    },
    getViewBinding() {
      if (this.levelIsRoot) { return 'root' }
      if (this.levelIsPage) { return 'page' }
      if (this.levelIsDetail) { return 'page'; /*'detail'*/ }
      return 'error-xx';
    },




    /**
     * -------------------------------- START v6 NEW BITS --------------------------------
     */



    card() {
      return this.story?.card || 'card';
    },
    primary () {
      return this.story?.primary ? this.getStory(this.story?.primary) : this.getStory(this.story?.slug);
    },
    others () {
      return this.story?.others?.map(s => this.getStory(s)) || [];
    },


    getStoriesV6() {

        // wait till we're actually loaded
        if (!this?.section?.slugs?.length) {
          return [];
        }

        // get all the stories, hydrated, sorted by score..
        let aStories = this.section.slugs
          ?.map(a => this.getStory(a))
          ?.sort((a, b) => {
            return a.score > b.score ? -1 : 1;
          });

        // return
        let aReturn = [];

        aStories.forEach((aStory) => {
          let aLocalTopics = aStory.topics;
          let bMatched = false;
          // console.log(`Topics: `, aLocalTopics);
          // look for a decent crossover..
          aReturn.some((aChunk) => {
            let iXO = this.v6_calcXO(aChunk.topics, aLocalTopics);
            // console.log(`iXO: ${iXO}, `);
            if (iXO > 1) {
              // stack my slug onto an existing chunk
              aChunk.others.push(aStory.slug);
              bMatched = true;
              return true;
            }
          });
          if (!bMatched) {
            // create a new chunk
            aReturn.push({
              topics: aStory.topics,
              primary: aStory.slug,
              others: [],
            });
          }
        });

        // consider the score as a proxy for how important the story is.
        let avgdev = this.v6_getAvgStd(aStories.map(a => a.score));
        
        // try and set some style
        let oCX = {};
        let oLimits = {
          top: {
            max: 1,
            next: 'major',
          },
          major: {
            max: 2,
            next: 'card',
          },
        };
        aReturn.forEach(a => {
          let aStory = this.getStory(a.primary);
          if (aStory?.score > (avgdev.avg + (avgdev.std / 2))) {
            a.card = (a.others.length >= 2) ? 'top' : 'major';
          } else if (aStory?.score < (avgdev.avg - (avgdev.std / 2))) {
            a.card = a.others.length ? 'feature' : 'minor';
          } else {
            a.card = 'card';
          }
          
          // limit (should really do{}while)
          if (oCX[a.card] >= oLimits[a.card]?.max) {
            a.card = oLimits[a.card]?.next;
          }

          // count stats
          oCX[a.card] = oCX[a.card] ? ++oCX[a.card] : 1;
        });

       

        console.log(`\x1b[33mgetStoriesV6(): `, aReturn, avgdev, oCX);
        
        return aReturn; 
    },

    state() {
      if (!this.$store.getters.getToken) {
        if (Math.random() > 0.5) {
          return {
            code: 'locked'
          }
        }
      }
      return this.$store.getters.readState(this.primary.slug) || {};
    },

    /**
     * -------------------------------- END v6 NEW BITS --------------------------------
     */

  },
  methods: {
    /**
     * -------------------------------- START v6 NEW BITS --------------------------------
     */

    v6_calcXO(a, b) {

      let iScore = 0;
      let aBase = [];
      let aComp = [];
      if ( a > b ) {
        aBase = a;
        aComp = b;
      } else {
        aBase = b;
        aComp = a;
      }

      aBase?.forEach(tBase => {
        aComp?.some(tComp => {
          if (tBase.topic_code == tComp.topic_code) {
            // accumulate the average score
            iScore += ((tBase.score + tComp.score) / 2);
            // and short circuit to the next base topic
            return true;
          }
        });
      });
      // console.log(`Match: ${iScore}`);
      return iScore;
    },
    v6_getAvgStd (scores) {
      let len = scores.length;
      let avg = scores.reduce((a, b) => a + b) / len;
      let std = Math.sqrt(scores.map(x => Math.pow(x - avg, 2)).reduce((a, b) => a + b) / len);
      return {
        len: len,
        avg: +avg.toFixed(2),
        std: +std.toFixed(2),
      };
    },





    /**
     * -------------------------------- END v6 NEW BITS --------------------------------
     */



    timeAgo(date) {
      return dayjs().to(dayjs(date));
    },

    clusterReadState(i) {
      return this.$userEngine.readState(this.clusterArticle(i).slug);
    },
    clusterIsExpanded(i) {
      // DEPRECATED
      return this.$dataEngine.clusterIsExpanded(i);
    },
    clusterIsFocused(i){
      // AS ABOVE - but betterly namedified.
      return this.$dataEngine.clusterIsExpanded(i);
    },
    clusterWhen(i) {
      return this.timeAgo(this.$dataEngine.clusterDate(i));
    },
    clusterDate(i) {
      return this.$dataEngine.clusterDate(i);
    },
    clusterUpdateStatement(i) {
      return this.$dataEngine.clusterUpdateStatement(i);
    },
    clusterHeadline(i) {
      return this.$dataEngine.clusterHeadline(i);
    },
    clusterSynopsis(i) {
      return this.$dataEngine.clusterSynopsis(i);
    },
    clusterPublishers(i) {
      return this.$dataEngine.clusterPublishers(i);
    },
    clusterSlug(i) {
      return this.$dataEngine.clusterSlug(i);
    },
    clusterArticle(i) {
      return this.$dataEngine.clusterArticle(i);
    },
    clusterTopics(i) {
      return this.$dataEngine.clusterTopics(i);
    },
    clusterStateClass(i) {
      return this.clusterIsExpanded(i) ? 'state-expanded' : 'state-inactive'; // state-active
    },

    getArticle(slug) {
      // console.log(slug);
      // console.log(this.model?.articles.map(o => o.slug));
      return this.model?.articles?.find(o => o.slug == slug) || {};
    },
    getNodePath(node, slug) {
      slug = slug || this.$route.params.slug;
      // TRANSITION: while v3 & v5 live toghether, /story isn't a given..
      let sBase = this.$route.fullPath.replace(/^\//,'').split('/')[0];
      // return `/v3/story/${slug}/${node}`;


      // DON'T LOSE THE QUERY STRING!
      let sQuery = Object.keys(this.$route.query).length ? '?' + new URLSearchParams(this.$route.query).toString() : '';

      // and return..
      return `/${sBase}/${slug}/${node}${sQuery}` ;
    },


    isCurrent(slug) {
      // return slug == this.getCurrentStory?.slug;
      return this.getCurrentStory?.slug == slug;
    },
    isNext(slug) {
      return slug == this.getNextStory?.slug;
    },
    isPrev(slug) {
      return slug == this.getPrevStory?.slug;
    },






    /**
     *  Utilities - From refactor of Nav > Group > Story
     *  ------------------------------------------------ 
     */

    getReadState(slug) {
      return this.$store.getters.readState(slug);
    },

    getStory(storySlug) {
      return this.$store.getters.getLibraryItem('story_stems_v3', storySlug) || {
        _error: `Failed to load: ${storySlug}`,
      };
    },

    getTopic(topicSlug) {
      return this.$store.getters.getLibraryItem('topics_v3', topicSlug) || {
        meta: {},
      };
    },

    getTopicGroup(topicGroupSlug) {
      // console.log('topicGroupSlug: ', topicGroupSlug);
      return topicGroupSlug?.split(':').map(s => this.getTopic(s));
    },
  }
}