import { render, staticRenderFns } from "./ProgressTag.vue?vue&type=template&id=49357624&scoped=true&"
import script from "./ProgressTag.vue?vue&type=script&lang=js&"
export * from "./ProgressTag.vue?vue&type=script&lang=js&"
import style0 from "./ProgressTag.vue?vue&type=style&index=0&id=49357624&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49357624",
  null
  
)

export default component.exports