// import Vue from 'vue';
import axios from "axios";
import store from "./store";
// REMOVED (July 2023) -- slow and no-one looks at it anyway! ... if re-added, sample or exclude GoogleBot!
// import bugsnagClient from "app/handlers/bugsnag/bugsnag";

var _ = require('lodash/core');

// DO NOT EDIT - USE localStorage.setItem('sandbox','_fred_') - to redirect API requests -------
var sandbox = (localStorage && localStorage.getItem('sandbox')) ? '.' + localStorage.getItem('sandbox') + '.face.deepbluesky.com' : '';
var dev_live = (localStorage && localStorage.getItem('live')) ? 'on' : 'off';
// ------------------------------------------------------------------------------------- -------
// console.log("ENV:", process.env.NODE_ENV);

const API = {
  
  // DO NOT EDIT - USE localStorage.setItem('sandbox','_fred_') - to redirect API requests -------
  url: process.env.NODE_ENV === 'production' ? '/api/2' : 'https://onesub.io' + sandbox + '/api/2',
  // ------------------------------------------------------------------------------------- -------


  get(path, params) {
    path = `${this.url}${path}`;
    return axios.get(`${path}`, { params: API.addToken(params) });
  },

  delete(path, params) {
    path = `${this.url}${path}`;
    return axios.delete(`${path}`, { params: API.addToken(params) });
  },

  post(path, params) {
    path = `${this.url}${path}`;
    // console.log('DOING PATH', path);
    return axios.post(`${path}`, API.buildQueryString(API.addToken(params)));
  },

  patch(path, params) {
    path = `${this.url}${path}`;
    return axios.patch(`${path}`, API.buildQueryString(API.addToken(params)));
  },

  postForm(path, form) {
    path = `${this.url}${path}`;
    var formData = new FormData(form);

    // Authorize the request
    let token = store.getters.getToken;
    if (token) {
      formData.set('token', token || '',);
    }

    return axios.post(`${path}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  addToken(params){
    params = params || {};
    // console.log(`addToken PARAMS: `, params.token, store.getters.getToken);
    let token = params.token || store.getters.getToken || '';
    if (token) {
      params.token = token || '';
    }
    params.cbx 		= Math.round(Math.random() * 1000000 * 1000000); // Cheap/quick cache-bust..
    if (sandbox) {
      params.live = dev_live;
    }
    // console.log(`addToken PARAMS: `, params);
    return params;
  },

  buildQueryString(params){
    return _.map(params,(val,key) => {return key + "=" + encodeURIComponent(val);}).join('&');
  },




  getSandbox() {
    return sandbox;
  },



  // Follow/unfollow
  checkIfFollowing(username) {
    let fetch = API.get(`/account/followstatus?screen_name=${username}`);

    fetch.then((response) => {
      let followObj = {
        username: username,
        follow: response.data.follows
      }
      store.commit('updateFollowingUsername', followObj);
    })
    .catch((error) => {
      // eslint-disable-next-line
      console.error(error);
    });

    return fetch;
  },

  followUser(username) {
    let post = API.post(`/account/follow`, {
      screen_name: username
    });

    post.then(() => {
      let followObj = { username: username, follow: true }
      store.commit('updateFollowingUsername', followObj);
    })
    .catch((error) => {
      // eslint-disable-next-line
      console.error(error);
    });

    return post;
  },

  unfollowUser(username) {
    let post = API.post(`/account/unfollow`, {
      screen_name: username
    });

    post.then(() => {
      let followObj = { username: username, follow: false }
      store.commit('updateFollowingUsername', followObj);
    })
    .catch((error) => {
      // eslint-disable-next-line
      console.error(error);
    });

    return post;
  },

  getChartTopics(topics) {
    let fetch = API.get(`/topics/multiple/${topics}?show-publishers=on`)
    
    fetch.then((response) => {
      Object.values(response.data).forEach(topic => {
        store.commit('addChartTopics', topic);
      });
    })
    .catch((error) => {
      // eslint-disable-next-line
      console.error(error);
    });

    return fetch;
  },

	getHeadlineTopicData(topic) {
		let fetch = API.get(`/data/` + topic)

		fetch.then((response) => {
			store.commit('addHeadlineTopicData', { topic: topic, data: response.data });
		})
		.catch((error) => {
			console.error(error);
		});

		return fetch;
	},

  getReadNext(){
    let fetch = API.get(`/readnext`);
    fetch.then((response) => {

      store.commit('addReadNext',response.data);
      // 1. Get all read next links
      let items = [];
      _.forEach(response.data, (item) => {
        if (!store.getters.getStory(item.slug)) {
          items.push(item.slug);
        }
      });

      if(!items.length) {
        return;
      }

      items = items.join(",");

      // 2. Get stories based on read next links
      let fetchStories = API.get(`/stories/multiple/?slugs=${items}`);
      fetchStories.then((articleResponse) => {

        // Add stories to store.
        _.forEach(articleResponse.data, (item) => {
          item = {
            'slug': item.slug,
            'added': new Date(),
            'data': item
          }
          store.commit('addStory',item);
        });
        store.commit('addReadNextStories',articleResponse.data);

        // 3. Find out if articles within stories are read or not.
        let fetchReadStatuses 	= API.get(`/stories/read/?slugs=${items}`);
        fetchReadStatuses.then((response) => {
          _.forEach(response.data, (item, key) => {
            item = {
              'slug': key,
              'data': item
            }
            store.commit('addArticleReadStatuses',item);
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });

      });
    })
    .catch((error) => {
      // eslint-disable-next-line
      console.error(error);
    });

    return fetch;
  },

  getAccount(token) {
    if (!token) {
      return;
    }

    let fetch = API.get(`/account`, {
      token: token || '',
    });

    return fetch.then((response) => {
      store.commit('updateAccount', response.data);
      // window.setTimeout(()=>{
      //   // console.log(Vue.prototype.EventBus);
      //   Vue.prototype.EventBus.$emit('api:account');
      // },1000);
    });
    // .catch((error) => {
    //   // eslint-disable-next-line
    //   // Check for 401, if 401, remove token from local storage
    //   bugsnagClient.notify(error);
    // });
  },

  getStripe(token) {
    if (!token) {
      return;
    }

    let fetch = API.get(`/account/stripe`, {
      token: token || '',
    });

    return fetch.then((response) => {
      store.commit('updateStripe', response.data);
      // window.setTimeout(()=>{
      //   // console.log(Vue.prototype.EventBus);
      //   Vue.prototype.EventBus.$emit('api:account');
      // },1000);
    });
    // .catch((error) => {
    //   // eslint-disable-next-line
    //   // Check for 401, if 401, remove token from local storage
    //   bugsnagClient.notify(error);
    // });
  },

  getPublishers(onlyActive = true) {
    if (!onlyActive) {
      let fetch = API.get(`/data/publishers`);
      fetch.then((response) => {
        store.commit('addPublishers', response.data);
      });
      // .catch((error) => {
      //   // eslint-disable-next-line
      //   // Check for 401, if 401, remove token from local storage
      //   bugsnagClient.notify(error);
      // });
    } else {
      let fetch = API.get(`/data/publishers`);
      fetch.then((response) => {
        store.commit('addPublishers', response.data);
      });
      // .catch((error) => {
      //   // eslint-disable-next-line
      //   // Check for 401, if 401, remove token from local storage
      //   bugsnagClient.notify(error);
      // });
    }
  },

  getEditions() {
    return store.dispatch('fetch',{ endpoint: 'editions' });
  },

  getStatus() {
    let fetch = API.get(`/data/status`);
    fetch.then((response) => {
      store.commit('addStatus', response.data);
    });
    // .catch((error) => {
    //   // eslint-disable-next-line
    //   // Check for 401, if 401, remove token from local storage
    //   bugsnagClient.notify(error);
    // });
  },

  getChallenges() {
    let fetch = API.get(`/account/challenges`);
    fetch.then((response) => {
      let microChallenges = []
      let completedChallenges = []

      // Could be empty
      if (response.data && response.data.forEach) {
        response.data.forEach(challenge => {
          if (challenge.completed) {
            completedChallenges.push(challenge);
          } else if (!challenge.completed) {
            microChallenges.push(challenge);
          }
        });
      }

      store.commit('addMicroChallenges', microChallenges);
      store.commit('addCompletedChallenges', completedChallenges);
    });
    // .catch((error) => {
    //   bugsnagClient.notify(error);
    // });
  },

  getD3GeoMap() {
    let map = 'https://onesub.io/images/d3/world-countries.json';
    let fetch 		= axios.get(map, {});

    fetch.then((response) => {
      response = {
        'slug': 'geo-map',
        'added': new Date(),
        'data': response.data
      }
      store.commit('addD3GeoMap',response);
    })
    .catch((error) => {
      // eslint-disable-next-line
      console.error(error);
    });
  },

  getTrendingTopics() {
    let fetchArticles = API.get(`/topics/trending`);

		return fetchArticles.then((response) => {
      store.commit('addTrendingTopics',response);
      return true;
		})
		.catch((error) => {
			// eslint-disable-next-line
			console.error(error);
      return error;
		});
  },

  getReport() {    
    let fetch	= API.get(`/account/report`);

    fetch.then((response) => {
      // Remove after testing - Shuffles editoral array
      const res = response;
      // for (let i = res.data.report.editorial.length - 1; i > 0; i--) {
      //   const j = Math.floor(Math.random() * (i + 1));
      //   [res.data.report.editorial[i], res.data.report.editorial[j]] = [res.data.report.editorial[j], res.data.report.editorial[i]];
      // }

      //
      response = {
        'slug': 'report',
        'added': new Date(),
        'data': res.data
      };

      store.commit('addReport', response);

      return true;
    })
    .catch((error) => {
      // eslint-disable-next-line
      console.error('Report Error', error);
      return false;
    });

    
  },
  
  getTopicCoverage() {
    let fetch 		= API.get(`/account/topics`);

    fetch.then((response) => {
      response = {
        'slug': 'history',
        'added': new Date(),
        'data': response.data
      }
      store.commit('addHistoryTopicCoverage',response);
    })
    .catch((error) => {
      // eslint-disable-next-line
      console.error(error);
    });
  },


  getPublisherCoverage() {
    let fetch 		= API.get(`/account/publishers`);

    fetch.then((response) => {
      response = {
        'slug': 'history',
        'added': new Date(),
        'data': response.data
      }
      store.commit('addHistoryPublisherCoverage',response);
    })
    .catch((error) => {
      // eslint-disable-next-line
      console.error(error);
    });
  },

  patchExcludedPublishers(publishers) {
    let publishersString = publishers.join();
    
    // If publisher array is empty, add ',' to string otherwise API may reject request
    if (publishersString === "") { publishersString = ","; }
    
    let patch = API.patch(`/account`, {'excludePublishers': publishersString})
    
    patch.then(() => {
      localStorage.removeItem('ios_publisher_exclusion_flow_completed');
      localStorage.removeItem('excluded_publishers');
    }).catch((error) => {
      console.error(error);
    })

		return patch;
  },

  // temporary (influencer programme - experimental)
  getInfluencerStats() {
    return API.get(`/account/influencer`);
  },
  

  // temporary (doesn't use store till we're ready to switch)  v3.0 -- can be REMOVED when 3.2 live
  getStoryV3_tmp(slug, params) {
    return API.get(`/stories/get/${slug}/?v3=on`, params);
  },


  // v6
  getHomeV6(params) {
    return store.dispatch('fetch',{ endpoint: 'edition_v6', params: params});
  },

  // v5
  getNext(params) {
    return store.dispatch('fetch',{ endpoint: 'next', params: params});
  },


  // temporary (doesn't use store till we're ready to switch)
  getHomeV3(edition, bForce) {
    return store.dispatch('fetch',{ endpoint: 'edition_v3', params: { locale: edition, _force: bForce }});
  },
  
  getStoryV3(slug) {
    return store.dispatch('fetch',{ endpoint: 'story_v3', params: { slug: slug }});
  },

  getStoryNodeV3(slug, node) {
    // return API.get(`/stories/get/${slug}/?v3=on`, params);
    // console.log('getStoryNodeV3', slug, node);
    return store.dispatch('fetch',{ endpoint: 'story_node_v3', params: { slug: slug, node: node }});
  },

  getReadStateV3(slugs) {
    if (!store.getters.isLoggedIn){
      return new Promise(() => {});
    }
    return API.get(`/account/read-status?slugs=${slugs.join(',')}`, {});
  },


  postReportArticle(params) {
    return API.post(`/articles/report`, {
      type: params.type,
      story: params.story,
      article: params.article,
    });
  },

  patchSettings(settings) {
    
    if (!store.getters.isLoggedIn){
      console.warn(`Can't actually PATCH settings for guests.`);
      return settings;
    }

    let patch = API.patch(`/account/settings`, { payload: JSON.stringify(settings), });
    
    patch.then(() => {
      // console.log("Settings PATCHed: ", JSON.stringify(settings));
    }).catch((error) => {
      console.error("Settings error: ", error);
    })

		return settings;
  },







  getSection(slug) {
    return store.dispatch('fetch',{ endpoint: 'sections', params: { slug: slug }});
  },

  getAnswers(slug) {
    return store.dispatch('fetch',{ endpoint: 'answers', params: { slug: slug }});
  },

  getAnswersAll() {
    return store.dispatch('fetch',{ endpoint: 'answers_all'});
  },

  getStory(slug) {
    return store.dispatch('fetch',{ endpoint: 'stories', params: { slug: slug }});
  },

  getArticle(slug){
    return store.dispatch('fetch',{ endpoint: 'articles', params: { slug: slug }});
  },

  getLink(link){
    return store.dispatch('fetch',{ endpoint: 'links', params: { link: link }});
  },

  getTopic(slug) {
    return store.dispatch('fetch',{ endpoint: 'topics', params: { slug: slug }});
  },

  getTopicArticles(slug){
    return store.dispatch('fetch',{ endpoint: 'topic_articles', params: { slug: slug }});
  },

  getHistory() {
    return store.dispatch('fetch',{ endpoint: 'history' });
  },

  // getReport() {
  //   return store.dispatch('fetch',{ endpoint: 'report' });
  // },

  // threads

  getThreadsMine() {
    return store.dispatch('fetch',{ endpoint: 'threads_mine' });
  },

  getThreadsScreenName(xname) {
    return store.dispatch('fetch',{ endpoint: 'threads_xname', params: { xname: xname }});
  },

  getThread(xname, slug) {
    return store.dispatch('fetch',{ endpoint: 'threads', params: { xname: xname, slug: slug }});
  },


  // profile
  getProfileFeed() {
    return store.dispatch('fetch',{ endpoint: 'threads_feed' });
  },

  getDemoProfileFeed() {
    return store.dispatch('fetch',{ endpoint: 'threads_demo_feed' });
  },


  // social graph

  getFollowers(xname) {
    return store.dispatch('fetch',{ endpoint: 'followers', params: { xname: xname }});
  },
  getFollowing(xname) {
    return store.dispatch('fetch',{ endpoint: 'following', params: { xname: xname }});
  },


  // cms & blog

  getContentMaps() {
    store.dispatch('fetch',{ endpoint: 'cms_blog_map' });
    store.dispatch('fetch',{ endpoint: 'cms_pages_map' });
  },

  getPage(slug) {
    return store.dispatch('fetch',{ endpoint: 'cms_pages_content', params: { slug: slug }});
  },
  getBlog(slug) {
    return store.dispatch('fetch',{ endpoint: 'cms_blog_content', params: { slug: slug }});
  },

  

  // tools & debugging

  getToolsClusterStories() {
    return store.dispatch('fetch',{ endpoint: 'tools_cluster_stories' });
  }
};

export default API;
